import { CookieContextProvider, ErrorBoundary, i18n, I18nextProvider, Toaster } from '@grunfin/ui-kit';
import '@grunfin/ui-kit/index.css';
import { Fragment, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PageLoader } from '~/modules/application/PageLoader';
import { Application } from './modules/application/Application';
import { CompanyLayout } from './modules/application/CompanyLayout';
import { MainLayout } from './modules/application/MainLayout';
import { NavigationBarProvider } from './modules/application/Navigation';
import { NotFoundView } from './modules/application/NotFoundView';
import { SessionProvider } from './modules/auth/SessionProvider';

import { AccountRoutes } from './modules/account/routes';
import { SplashLayout } from './modules/application/SplashLayout';
import { ForgotPasswordView } from './modules/auth/ForgotPasswordView';
import { LoginView } from './modules/auth/LoginView';
import { LogoutView } from './modules/auth/LogoutView';
import { VerifyEmailView } from './modules/auth/VerifyEmailView';
import { DashboardView } from './modules/portfolio/DashboardView';
import { PortfolioRoutes } from './modules/portfolio/routes';
import { SupportRoutes } from './modules/support/routes';

// Add project specific namespace bundles
import './i18n';
import { HeaderlessLayout } from './modules/application/HeaderlessLayout';
import { CompanyRoutes } from './modules/company/routes';
import './sentry';

// import fonts and etc
import '../index.css';

// polyfills
/*
 * Prevents the error of "ReferenceError: Can't find variable: ResizeObserver"
 * for safari 13 (and other browsers who do not support ResizeObserver)
 */
import { install } from 'resize-observer';
import ActivationView, {
  ActivationLink,
  ActivationViewType,
  useActivationViewComponents,
  VerificationLink,
} from './modules/portfolio/ActivateView/ActivationView';

if (!window.ResizeObserver) install();

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

const createRoutes = (routes: string[], components: { [key: string]: JSX.Element }) => {
  const routeLinks = Object.values(routes);

  const links = routeLinks
    .map((link) => {
      const element = components[link];
      if (!element) return null;
      return <Route key={link} path={link} element={element} />;
    })
    .filter(Boolean);

  return links;
};

const PortfolioActivationRoutes = () => {
  const components = useActivationViewComponents(ActivationViewType.PORTFOLIO);
  return createRoutes(Object(ActivationLink), components);
};

const VerificationRoutes = () => {
  const components = useActivationViewComponents(ActivationViewType.VERIFICATION);
  return createRoutes(Object(VerificationLink), components);
};

const ModuleRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<DashboardView />} />
        <Route path="portfolio/*" element={<PortfolioRoutes />} />
        <Route path="account/*" element={<AccountRoutes />} />
      </Route>
      <Route path="portfolio/:id/activate/*" element={<ActivationView />}>
        {PortfolioActivationRoutes()}
      </Route>

      <Route path="kyc/*" element={<ActivationView />}>
        {VerificationRoutes()}
      </Route>

      <Route element={<HeaderlessLayout />}>
        <Route path="support/*" element={<SupportRoutes />} />
      </Route>

      <Route element={<CompanyLayout />}>
        <Route path="company/*" element={<CompanyRoutes />} />
      </Route>

      <Route element={<SplashLayout />}>
        <Route path="/login" element={<LoginView />} />
        <Route path="/forgot-password" element={<ForgotPasswordView />} />
        <Route path="/logout" element={<LogoutView />} />
        <Route path="/verify-email" element={<VerifyEmailView />} />
      </Route>

      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary>
        <Suspense fallback={<PageLoader />}>
          <CookieContextProvider>
            <QueryClientProvider client={queryClient}>
              <Router>
                <SessionProvider>
                  <NavigationBarProvider>
                    <Fragment>
                      <Application>
                        <Routes>
                          <Route path="en/*" element={<ModuleRoutes />} />
                          <Route path="de/*" element={<ModuleRoutes />} />
                          <Route path="et/*" element={<ModuleRoutes />} />
                          <Route path="*" element={<ModuleRoutes />} />
                        </Routes>
                      </Application>
                    </Fragment>
                  </NavigationBarProvider>
                </SessionProvider>
              </Router>
              <Toaster />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </CookieContextProvider>
        </Suspense>
      </ErrorBoundary>
    </I18nextProvider>
  </StrictMode>,
);

if (import.meta.hot) {
  import.meta.hot.accept();
}
