import { DocumentIcon, SettingsIcon, UserIcon, useTranslation } from '@grunfin/ui-kit';
import { Fragment, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { useNavigationTitle } from '~/modules/application/Navigation';
import { useDocumentTitle } from '~/utils';

export const AccountLayout = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('account');
  useNavigationTitle([[t('home', { ns: 'general' }), '/'], t('navigation_title')]);
  useDocumentTitle(t('document_title'));

  return (
    <div tw="grid grid-cols-12 w-full">
      <aside tw="col-span-full md:col-span-4 justify-self-start">
        <nav tw="md:rounded-2xl bg-forest-green-50 md:px-6 py-4 md:py-5 whitespace-nowrap md:whitespace-normal overflow-auto w-screen md:w-auto">
          <SideLink end to="/account">
            <Fragment>
              <SettingsIcon /> {t('general.title')}
            </Fragment>
          </SideLink>
          <SideLink to="/account/details">
            <Fragment>
              <UserIcon /> {t('details.title')}
            </Fragment>
          </SideLink>
          {/*<SideLink to="/account/risk-profile">*/}
          {/*  <Fragment>*/}
          {/*    <RiskProfileIcon /> {t('risk_profile.title')}*/}
          {/*  </Fragment>*/}
          {/*</SideLink>*/}
          <SideLink to="/account/documents">
            <Fragment>
              <DocumentIcon /> {t('documents.title')}
            </Fragment>
          </SideLink>
        </nav>
      </aside>
      <section tw="col-span-full md:col-span-7 md:shadow md:rounded-2xl">{children}</section>
    </div>
  );
};

const SideLink = styled(NavLink).attrs({
  className: ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : ''),
})`
  ${tw`transition-colors inline-flex md:flex items-center py-2 mx-4 md:mx-0 md:py-3 text-forest-green-400 md:text-lg`};

  svg {
    ${tw`transition-colors mr-3 md:mr-6 rounded bg-white text-forest-green-400`};
  }

  &:hover {
    ${tw`text-forest-green-700`};
  }

  &.active {
    ${tw`text-forest-green-700`};

    svg {
      ${tw`bg-forest-green-700 text-forest-green-200`};
    }
  }
`;
