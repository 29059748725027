import { Link, useMatch } from 'react-router-dom';
import tw from 'twin.macro';

import {
  Button,
  ButtonVariant,
  FacebookIcon,
  InstagramIcon,
  Language,
  LanguagePicker,
  LanguagePickerTheme,
  LanguagePickerVariant,
  LinkedinIcon,
  TwitterIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { useSession } from '~/modules/auth/SessionProvider';
import { useSubmitSettings } from '../account/queries';

const webOrigin = 'https://grunfin.com';

export enum FooterVariant {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  DARK_GREEN = 'DARK_GREEN',
}

type FooterProps = {
  variant?: FooterVariant;
  showLinks?: boolean;
};

const getTextStyle = (variant: FooterVariant) => [
  tw`text-base`,
  variant === FooterVariant.LIGHT && tw`text-alps-blue-400 hover:text-alps-blue-700 transition-colors`,
  variant === FooterVariant.DARK && tw`text-white opacity-50 hover:opacity-100 transition-opacity`,
  variant === FooterVariant.DARK_GREEN && tw`text-white opacity-50 hover:opacity-100 transition-opacity`,
];

const defaultVariant = FooterVariant.LIGHT;
export const Footer = (props: FooterProps) => {
  const { variant = defaultVariant, showLinks = true } = props;
  const { i18n, t } = useTranslation('application');
  const session = useSession();
  const submitSettings = useSubmitSettings();
  const match = useMatch('/company/:companyId/benefit/:benefitId/*');
  const { companyId, benefitId } = match?.params ?? {};
  const isCompanyRoute = companyId != null && benefitId != null;

  const handleLanguageChange = (lang: Language) => {
    if (!session.authenticated) return;
    submitSettings.mutate({ locale: lang.code });
  };

  // use 'to' when routing within the app, and href if outside
  const linkList = [
    {
      title: !session.authenticated ? t('footer.landing') : t('footer.home'),
      to: session.authenticated && '/',
      href: !session.authenticated && webOrigin,
    },
    {
      title: t('footer.log_in'),
      to: '/login',
      hidden: session.authenticated,
    },
    {
      title: t('footer.my_account'),
      to: '/account',
      disabled: !session.authenticated,
    },
    {
      title: t('footer.impact'),
      href: `${webOrigin}/${i18n.resolvedLanguage}/impact`,
      hidden: isCompanyRoute,
    },
    {
      title: t('footer.team'),
      href: `${webOrigin}/${i18n.resolvedLanguage}/team`,
      hidden: isCompanyRoute,
    },
    {
      title: t('footer.blog'),
      href: `${webOrigin}/blog/${i18n.resolvedLanguage !== 'et' ? i18n.resolvedLanguage : ''}`,
      hidden: isCompanyRoute,
    },
    {
      title: t('company:employees.navigation_title'),
      to: `/company/${companyId}/benefit/${benefitId}`,
      hidden: !isCompanyRoute,
    },
    {
      title: t('company:employees.upload.title'),
      to: `/company/${companyId}/benefit/${benefitId}/memberships/upload`,
      hidden: !isCompanyRoute,
    },
    {
      title: t('company:journals.navigation_title'),
      to: `/company/${companyId}/benefit/${benefitId}/journals`,
      hidden: !isCompanyRoute,
    },
    {
      title: t('company:payments.upload.document_title'),
      to: `/company/${companyId}/benefit/${benefitId}/payments/upload`,
      hidden: !isCompanyRoute,
    },
  ];

  return (
    <footer
      css={[
        variant === FooterVariant.LIGHT && tw`bg-alps-blue-100`,
        variant === FooterVariant.DARK && tw`bg-sky-blue-700`,
        variant === FooterVariant.DARK_GREEN && tw`bg-green-800`,
      ]}
    >
      <div tw="w-full max-w-screen-xl mx-auto px-4 py-8 md:py-12">
        <div tw="flex flex-col gap-12">
          <div tw="flex flex-row flex-wrap gap-8 justify-between flex-1">
            <ol tw="columns-2 gap-4" css={[showLinks && tw`sm:w-1/2 gap-14 w-full`]}>
              {showLinks ? (
                linkList.map((item) => {
                  if (item.hidden) return null;

                  const isRouterLink = !!item.to;
                  const isDisabled = item.disabled;
                  const linkStyle = [
                    tw`flex w-full md:text-lg`,
                    ...getTextStyle(variant),
                    isDisabled && tw`opacity-50 cursor-not-allowed`,
                    isDisabled && variant === FooterVariant.LIGHT && tw`hover:text-alps-blue-400`,
                    isDisabled && variant === FooterVariant.DARK && tw`hover:opacity-50`,
                    isDisabled && variant === FooterVariant.DARK_GREEN && tw`hover:opacity-50`,
                  ];

                  return (
                    <li key={item.title} tw="flex py-1 md:py-2">
                      {isRouterLink && (
                        <Link css={linkStyle} to={item.to as string} onClick={(e) => isDisabled && e.preventDefault()}>
                          {item.title}
                        </Link>
                      )}
                      {!isRouterLink && item.href && (
                        <a
                          css={linkStyle}
                          href={item.href}
                          target="_blank"
                          rel="noopener"
                          onClick={(e) => isDisabled && e.preventDefault()}
                        >
                          {item.title}
                        </a>
                      )}
                    </li>
                  );
                })
              ) : (
                <>
                  <Link to="/">
                    <Button variant={ButtonVariant.NEW_MEDIUM} className="rounded-full">
                      {t('footer.return_portfolio')}
                    </Button>
                  </Link>
                  <Link to="/logout">
                    <Button variant={ButtonVariant.NEW_MEDIUM} className="rounded-full">
                      {t('footer.sign_out')}
                    </Button>
                  </Link>
                </>
              )}
            </ol>
            <ol tw="flex flex-row flex-wrap justify-end gap-4">
              {session.authenticated && showLinks && (
                <li>
                  <Button variant={ButtonVariant.SECONDARY} as="a" href="/logout" className="rounded-full">
                    {t('footer.sign_out')}
                  </Button>
                </li>
              )}
              <li>
                <LanguagePicker
                  theme={variant !== FooterVariant.LIGHT ? LanguagePickerTheme.DARK : LanguagePickerTheme.LIGHT}
                  variant={LanguagePickerVariant.GLOBE}
                  onLanguageChange={handleLanguageChange}
                />
              </li>
            </ol>
          </div>
          <Disclaimers {...props} />
        </div>
      </div>
    </footer>
  );
};

export const Disclaimers = ({ variant = defaultVariant }: FooterProps) => {
  const { t } = useTranslation('application');

  const preContractualDisclaimersList = [
    {
      title: t('footer.copyright', { year: new Date().getFullYear() }),
    },
    {
      title: t('footer.rights'),
    },
    {
      title: t('footer.landing'),
      link: webOrigin,
    },
    {
      title: t('footer.privacy_policy'),
      link: t('footer.privacy_policy_link'),
    },
    {
      title: t('footer.cookie_policy'),
      link: t('footer.cookie_policy_link'),
    },
    {
      title: t('footer.impressum'),
      link: `${webOrigin}/impressum`,
    },
    {
      title: t('footer.faq'),
      link: t('footer.faq_link'),
    },
    {
      title: t('footer.contact'),
      link: `${webOrigin}/contact`,
    },
  ];

  const iconStyle = [
    variant === FooterVariant.LIGHT && tw`text-alps-blue-400 hover:text-alps-blue-500 transition-colors`,
    variant === FooterVariant.DARK && tw`opacity-50 text-white hover:opacity-100 transition-opacity`,
    variant === FooterVariant.DARK_GREEN && tw`opacity-50 text-white hover:opacity-100 transition-opacity`,
  ];

  const icons = [
    {
      icon: <TwitterIcon css={iconStyle} />,
      link: 'https://twitter.com/grunfin',
    },
    {
      icon: <LinkedinIcon css={iconStyle} />,
      link: 'https://www.linkedin.com/company/grunfin',
    },
    {
      icon: <InstagramIcon css={iconStyle} />,
      link: 'https://www.instagram.com/grunfin_',
    },
    {
      icon: <FacebookIcon css={iconStyle} />,
      link: 'https://www.facebook.com/grunfin',
    },
  ];
  return (
    <div tw="flex flex-row flex-wrap gap-8 justify-between w-full">
      <ol tw="flex flex-row flex-wrap gap-3">
        {preContractualDisclaimersList.map((item, index) => {
          const isLink = item.link != null;
          return (
            <li key={index}>
              {!isLink && (
                <span
                  css={[
                    ...getTextStyle(variant),
                    variant === FooterVariant.LIGHT && tw`hover:text-alps-blue-400`,
                    variant === FooterVariant.DARK && tw`hover:opacity-50`,
                  ]}
                >
                  {item.title}
                </span>
              )}
              {isLink && (
                <a href={item.link} target="_blank" rel="noopener" css={getTextStyle(variant)}>
                  {item.title}
                </a>
              )}
            </li>
          );
        })}
      </ol>
      <ol tw="flex flex-row flex-wrap gap-6">
        {icons.map((item, index) => (
          <li key={index} tw="flex items-center">
            <a href={item.link} target="_blank" rel="noopener">
              {item.icon}
            </a>
          </li>
        ))}
      </ol>
    </div>
  );
};
