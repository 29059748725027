import { DecisionStatus, FocusArea, toast, Trans, useTranslation } from '@grunfin/ui-kit';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import 'twin.macro';

import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { setBackendValidationErrors } from '~/utils';
import { Heading } from '../../components/Heading';
import { usePortfolio } from '../../hooks';
import { useGetExitStatus, useUpdatePortfolio } from '../../queries';
import { UpdatePortfolio } from '../../types';
import { SettingsForm } from './SettingsForm';
import React from 'react';

export const Settings = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  // const navigate = useNavigate();
  const updatePortfolio = useUpdatePortfolio(portfolio.id);
  const exitStatus = useGetExitStatus(portfolio.id);
  const hasPendingExit = exitStatus?.data?.some((r) => [DecisionStatus.CREATED, DecisionStatus.APPROVED].includes(r));
  // Allow exited portfolios to access this route for IBAN entry-viewing - they won't see anything else here, anyway
  // if (portfolio.status === PortfolioStatus.EXITED && portfolio.iban) {
  //   navigate('/', { replace: true });
  //   return null;
  // }

  if (updatePortfolio.isError) {
    return <ContactSupportOverlay title={t('error')} onClose={updatePortfolio.reset} error={updatePortfolio.error} />;
  }

  const onSubmit = async (values: UpdatePortfolio, setError: UseFormSetError<UpdatePortfolio>) => {
    try {
      await updatePortfolio.mutateAsync({
        ...values,
        areaClimateChange: values.focusAreas.includes(FocusArea.CLIMATE_CHANGE),
        areaGenderEquality: values.focusAreas.includes(FocusArea.GENDER_EQUALITY),
        areaHealth: values.focusAreas.includes(FocusArea.HEALTH),
        areaSP500: values.focusAreas.includes(FocusArea.SP500),
      });
      toast.success(t('update.success'));
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
    }
  };
  const Separator = () => <hr className="border-neutral-100 my-10 px-6" />;

  return (
    <div tw="flex flex-col md:w-3/5 md:self-center pt-8 pb-16">
      <Heading primary>{t('edit')}</Heading>
      <div tw="px-6">
        <SettingsForm portfolio={portfolio} onSubmit={onSubmit} />
      </div>
      <Separator />
      <Heading primary noMargin>
        {t('exit.title')}
      </Heading>
      <div tw="px-6">
        <p tw="text-neutral-500 mb-8">
          <Trans i18nKey="portfolio:dashboard.shutdown.description" />
        </p>
        <p tw="mt-2 text-gray-400 text-sm">{hasPendingExit && t('exit.in_process')}</p>
      </div>
    </div>
  );
};
