import { differenceInMonths, ErrorIcon, Popover, PortfolioStatus, useTranslation } from '@grunfin/ui-kit';
import { compact } from 'lodash-es';
import React, { useEffect, useMemo, useRef } from 'react';
import { NavLink, useMatch, useParams } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';

import { useGetPortfolioTransactions } from '~/modules/portfolio/queries';
import { PortfolioFeeStatus, PortfolioTransactionType } from '~/modules/portfolio/types';
import { usePortfolio } from '../hooks';

export const Navigation = () => {
  const links = useLinks();

  return (
    <div tw="hidden md:flex self-center space-x-12 text-lg p-8">
      {links.map(({ to, label, errors, disabled }) => (
        <Link
          key={to}
          to={to}
          onClick={disabled ? (e) => e.preventDefault() : undefined}
          className={disabled ? 'disabled' : undefined}
        >
          {label}
          {errors && errors.length > 0 && <ErrorTooltip errors={errors} />}
        </Link>
      ))}
    </div>
  );
};

export const MobileNavigation = () => {
  const links = useLinks();
  const match = useMatch('/portfolio/:id/:tab');
  const containerRef = useRef<HTMLDivElement>(null);
  const anchorRefs = useRef<Record<string, HTMLAnchorElement | null>>({});
  const tab = match?.params.tab ?? '';

  useEffect(() => {
    const anchor = anchorRefs.current[tab];
    if (anchor && containerRef.current) {
      containerRef.current.scrollLeft =
        anchor.offsetLeft + anchor.offsetWidth / 2 - containerRef.current.offsetWidth / 2;
    }
  }, [tab]);

  return (
    <div css={styles.mobile.container} ref={containerRef}>
      {links.map(({ to, label, errors, disabled }) => (
        <Link
          key={to}
          to={to}
          ref={(el) => (anchorRefs.current[to] = el)}
          onClick={disabled ? (e) => e.preventDefault() : undefined}
          className={disabled ? 'disabled' : undefined}
        >
          {label}
          {errors && errors.length > 0 && <ErrorTooltip errors={errors} />}
        </Link>
      ))}
    </div>
  );
};

const ErrorTooltip = ({ errors }: { errors: string[] }) => {
  return (
    <Popover
      content={
        <div className="bg-white rounded shadow-inner p-2">
          {errors.map((error, i) => (
            <div key={i}>{error}</div>
          ))}
        </div>
      }
    >
      <ErrorIcon tw="rounded bg-dawn-200 text-gray-900 w-5 h-5 ml-2 self-center" />
    </Popover>
  );
};

const useLinks = () => {
  const { t } = useTranslation('portfolio');
  const errors = useErrors();
  const portfolio = usePortfolio();
  const isPortfolioExited = portfolio?.status === PortfolioStatus.EXITED;

  return useMemo(
    () => [
      { to: 'structure', label: t('details.navigation.structure') },
      { to: 'payments', label: t('details.navigation.payments'), errors, disabled: isPortfolioExited },
      { to: 'reports', label: t('details.navigation.reports') },
      {
        to: 'contribution',
        label: t('details.navigation.contribution'),
        disabled: portfolio.status !== PortfolioStatus.ACTIVE,
      },
      { to: 'settings', label: t('details.navigation.settings'), disabled: isPortfolioExited && portfolio.iban },
    ],
    [t, errors, isPortfolioExited],
  );
};

export const useErrors = (): string[] => {
  const { id } = useParams<'id'>();
  const { t } = useTranslation('portfolio');
  const { data: transactions = [] } = useGetPortfolioTransactions(id);

  return useMemo<string[]>(() => {
    if (!transactions.length) {
      return [];
    }

    const fees = transactions.filter((p) => p.type === PortfolioTransactionType.FEE);
    const unpaidFees = fees.filter((p) => p.status === PortfolioFeeStatus.WAITING_PAYMENT);
    const threeUnpaidFees = unpaidFees && unpaidFees.length >= 3 && t('details.navigation.unpaid_fees');
    const paymentsBehind =
      fees &&
      fees[0] &&
      differenceInMonths(new Date(), new Date(fees[0].date)) >= 3 &&
      t('details.navigation.unpaid_commitments');

    return compact([paymentsBehind, threeUnpaidFees]);
  }, [t, transactions]);
};

const Link = styled(NavLink)`
  ${tw`flex text-alps-blue-400 hover:text-alps-blue-700 md:text-gray-400 transition-colors`};

  &.active {
    ${tw`text-alps-blue-700`};
  }

  &.disabled {
    ${tw`text-gray-400 hover:text-gray-400 cursor-not-allowed`}
  }
`;

const styles = {
  mobile: {
    container: [
      tw`relative flex md:hidden gap-6 bg-alps-blue-50 mb-8 px-8 py-4 overflow-x-auto whitespace-nowrap`,
      css({
        msOverflowStyle: 'none',
        msScrollbarTrackColor: 'none',
        '&::-webkit-scrollbar': tw`hidden`,
        '& a:first-child': tw`ml-auto`,
      }),
      css`
        & a:last-child {
          padding-right: 2rem !important;
        }
      `,
    ],
  },
};
