import { useTranslation } from '@grunfin/ui-kit';
import { ReactNode } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

export const AuthContainer = ({ children }: { children: ReactNode }) => {
  const { search } = useLocation();
  const { t } = useTranslation('auth');

  return (
    <div tw="rounded-2xl bg-white shadow px-6 md:px-12 pb-10 pt-8 w-full">
      <div tw="flex justify-between mb-10 text-lg text-gray-400 text-center">
        <Link to={`/login${search}`}>{t('log_in')}</Link>
      </div>
      {children}
    </div>
  );
};

const Link = styled(NavLink).attrs({ className: ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : '') })`
  ${tw`transition-colors py-2`};

  &:hover {
    ${tw`hover:text-alps-blue-700`};
  }

  &.active {
    ${tw`text-alps-blue-700 border-b-2 border-alps-blue-700`};
  }
`;
